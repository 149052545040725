export const types = {
  ADD: 'ADD',
  REMOVE_DENOM: 'REMOVE_DENOM',
  REMOVE_PRODUCT: 'REMOVE_PRODUCT',
  CHANGE_QUANTITY: 'CHANGE_QUANTITY',
  CHANGE_ID: 'CHANGE_ID',
  CLEAR_CART: 'CLEAR_CART',
  LOCK: 'LOCK',
  UNLOCK: 'UNLOCK',
  SYNC: 'SYNC',
};

export const addToCart = (prodCode, denom, quantity = 1) => ({
  type: types.ADD,
  payload: { prodCode, denom, quantity },
});

export const removeFromCart = (prodCode, denom = undefined) => {
  if (!denom) {
    return {
      type: types.REMOVE_PRODUCT,
      payload: prodCode,
    };
  }
  return {
    type: types.REMOVE_DENOM,
    payload: { prodCode, denom },
  };
};

export const changeQuantity = (prodCode, denom, quantity, onChange = false) => ({
  type: types.CHANGE_QUANTITY,
  payload: { prodCode, denom, quantity, onChange },
});

export const changeID = () => ({
  type: types.CHANGE_ID,
});

export const clearCart = () => ({
  type: types.CLEAR_CART,
});

export const lockCart = () => ({
  type: types.LOCK,
});

export const unlockCart = () => ({
  type: types.UNLOCK,
});

export const syncCart = (newState) => ({
  type: types.SYNC,
  payload: newState,
});
