import { v4 as uuid, validate } from 'uuid';
import structuredClone from 'core-js-pure/stable/structured-clone';
import { types } from '../actions/CartActions';
import { types as authTypes } from '../actions/AuthActions';
import { MAX_DENOM_QUANTITY } from '../../utils/Constants';

function compareCartItemEntry([prodCodeA], [prodCodeB]) {
  if (prodCodeA === 'PAYSAU') return -1;
  if (prodCodeB === 'PAYSAU') return 1;
  return prodCodeA > prodCodeB ? 1 : -1;
}

function compareDenomEntry([denomA], [denomB]) {
  return denomB - denomA; // only works if the keys within the prodCode objects are numbers
}

function normalizeAndSave(state) {
  const statePointer = state;
  statePointer.items = Object.fromEntries(
    Object.entries(state.items)
      .sort(compareCartItemEntry)
      .map(([prodCode, denomQuantity]) => [
        prodCode,
        Object.fromEntries(Object.entries(denomQuantity).sort(compareDenomEntry)),
      ])
  );
  localStorage.setItem('cart', JSON.stringify(statePointer));

  return statePointer;
}

// const initialState = {
//   id: 'uuid',
//   items: {
//     PAYSAU: {
//       '10': 1,
//     },
//     CINEGU: {
//       '5': 2,
//       '10': 1,
//     },
//     NNTNDO: {
//       '5': 2,
//       '10': 1,
//     },
//   },
// };
const initialState = {
  id: null,
  items: {},
};

function validateDenom(denomStr) {
  const denom = parseFloat(denomStr);
  return Number.isFinite(denom) && denom > 0 && denom < 9999;
}
function validateQuantity(quantityStr) {
  if (!Number.isInteger(quantityStr)) return false;
  const quantity = parseInt(quantityStr, 10);
  return quantity > 0 && quantity <= MAX_DENOM_QUANTITY;
}

function parseCartJsonString(jsonStr) {
  let state;
  try {
    state = JSON.parse(jsonStr);
  } catch {
    return initialState;
  }
  if (
    !state ||
    !validate(state.id) ||
    !state.items ||
    typeof state.items !== 'object' ||
    Array.isArray(state.items)
  ) return initialState;

  state.items = Object.fromEntries(
    Object.entries(state.items)
      .map(([prodCode, denomQuantityPairs]) => {
        if (prodCode === 'PAYSAU') {
          const validDenom = Object.keys(denomQuantityPairs).find(validateDenom);
          return validDenom ? [prodCode, { [parseFloat(validDenom)]: 1 }] : undefined;
        }
        const validDenomQuantityPairs = Object.entries(denomQuantityPairs)
          .filter(([denom, quantity]) => (validateDenom(denom) && validateQuantity(quantity)))
          .map(([denom, quantity]) => [parseFloat(denom), parseInt(quantity, 10)]);
        return validDenomQuantityPairs.length > 0 ? [
          prodCode,
          Object.fromEntries(validDenomQuantityPairs),
        ] : undefined;
      })
      .filter((item) => item) // filter out undefined
  );
  return Object.fromEntries(Object.entries(state).filter(([k]) => ['id', 'items'].includes(k)));
}

export function loadState() {
  return normalizeAndSave(parseCartJsonString(localStorage.getItem('cart')));
}

// eslint-disable-next-line default-param-last
function CartReducer(state = initialState, { type, payload }) {
  switch (type) {
    case types.ADD: {
      if (state.locked) return state;
      const { prodCode, denom, quantity } = payload;
      const stateCopy = structuredClone(state);
      if (!validate(stateCopy.id) || Object.keys(stateCopy.items).length < 1) stateCopy.id = uuid();

      if (prodCode === 'PAYSAU') {
        stateCopy.items[prodCode] = { [denom]: 1 };
      } else if (!stateCopy.items[prodCode]) {
        stateCopy.items[prodCode] = { [denom]: quantity };
      } else if (!stateCopy.items[prodCode][denom]) {
        stateCopy.items[prodCode][denom] = quantity;
      } else {
        stateCopy.items[prodCode][denom] += quantity;
        if (stateCopy.items[prodCode][denom] >
          MAX_DENOM_QUANTITY) stateCopy.items[prodCode][denom] = MAX_DENOM_QUANTITY;
      }

      return normalizeAndSave(stateCopy);
    }
    case types.CHANGE_QUANTITY: {
      if (state.locked) return state;
      const { prodCode, denom, quantity, onChange } = payload;
      const stateCopy = structuredClone(state);

      if (prodCode === 'PAYSAU' && quantity > 0) {
        stateCopy.items[prodCode] = { [denom]: 1 };
      } else if (quantity <= 0 && !onChange) {
        delete stateCopy.items[prodCode][denom];
        if (Object.keys(stateCopy.items[prodCode]).length === 0) delete stateCopy.items[prodCode];
      } else {
        stateCopy.items[prodCode][denom] = quantity;
      }

      return onChange ? stateCopy : normalizeAndSave(stateCopy);
    }
    case types.CHANGE_ID: {
      const stateCopy = structuredClone(state);
      stateCopy.id = uuid();
      return normalizeAndSave(stateCopy);
    }
    case types.REMOVE_DENOM: {
      if (state.locked) return state;
      const { prodCode, denom } = payload;
      const stateCopy = structuredClone(state);

      if (prodCode === 'PAYSAU') {
        delete stateCopy.items[prodCode];
      } else {
        delete stateCopy.items[prodCode][denom];
        if (Object.keys(stateCopy.items[prodCode]).length === 0) delete stateCopy.items[prodCode];
      }
      return normalizeAndSave(stateCopy);
    }
    case types.REMOVE_PRODUCT: {
      if (state.locked) return state;
      const stateCopy = structuredClone(state);
      delete stateCopy.items[payload];
      return normalizeAndSave(stateCopy);
    }
    case types.LOCK:
      return normalizeAndSave({ ...state, locked: Date.now() });
    case types.UNLOCK:
      return normalizeAndSave({ ...loadState(), locked: false });
    case types.SYNC:
      if (state.locked) return state;
      return normalizeAndSave(parseCartJsonString(payload));
    case authTypes.CLEAR_SESSION:
    case types.CLEAR_CART:
      return normalizeAndSave({ ...initialState, locked: false });
    default:
      return state;
  }
}

export default CartReducer;
