import Amplitude from 'amplitude-js';
import { isDevelopment } from './HostingEnv';

const AMPLITUDE_SESSION_TIMEOUT = 20 * 60 * 1000;
const ampLogStyle = 'background-color:aqua; color:black;';

const logToConsole = isDevelopment ? console.log : () => {}; // eslint-disable-line no-console

if (typeof window !== 'undefined') {
  Amplitude.getInstance().init(process.env.NEXT_PUBLIC_AMPLITUDE_KEY, null, {
    includeReferrer: true,
    includeUtm: true,
    includeGclid: true,
    sessionTimeout: AMPLITUDE_SESSION_TIMEOUT,
  });
  Amplitude.getInstance().setVersionName(process.env.NEXT_PUBLIC_VERSION);
}

export const getAmpSessionID = () => Amplitude.getInstance().getSessionId();
export const setAmpUser = (email) => {
  logToConsole(`%c[Amplitude]%c Setting user ID: ${email}`, ampLogStyle, '');
  Amplitude.getInstance().setUserId(email);
};
export const setUserProps = (properties) => {
  logToConsole('%c[Amplitude]%c Setting user property:', ampLogStyle, '', properties);
  Amplitude.getInstance().setUserProperties(properties);
};

export const logAmpEvent = (event, properties = undefined) => {
  logToConsole(
    `Amplitude event: %c${event}`,
    ampLogStyle,
    properties ? 'with properties:' : '',
    properties || ''
  );
  Amplitude.getInstance().logEvent(event, properties);
};

export const events = {
  // landing
  USER_VIEWED_MAIN_LANDING_PAGE: 'USER_VIEWED_MAIN_LANDING_PAGE',
  USER_CLICKED_MAIN_LANDING_SEARCH_BAR: 'USER_CLICKED_MAIN_LANDING_SEARCH_BAR',
  USER_CLICKED_MAIN_LANDING_SEARCH_BUTTON: 'USER_CLICKED_MAIN_LANDING_SEARCH_BUTTON',
  USER_SELECTED_MAIN_LANDING_PRODUCT_FILTER: 'USER_SELECTED_MAIN_LANDING_PRODUCT_FILTER',
  USER_UNSELECTED_MAIN_LANDING_PRODUCT_FILTER: 'USER_UNSELECTED_MAIN_LANDING_PRODUCT_FILTER',
  USER_CLICKED_MAIN_LANDING_PRODUCT: 'USER_CLICKED_MAIN_LANDING_PRODUCT',
  USER_CLICKED_MAIN_LANDING_SKIP_TO_CART_BUTTON: 'USER_CLICKED_MAIN_LANDING_SKIP_TO_CART_BUTTON',
  USER_CLICKED_MAIN_LANDING_CLEAR_FILTERS_BUTTON: 'USER_CLICKED_MAIN_LANDING_CLEAR_FILTERS_BUTTON',
  USER_CLICKED_MAIN_LANDING_CHECKOUT_NOW_BUTTON: 'USER_CLICKED_MAIN_LANDING_CHECKOUT_NOW_BUTTON',

  // auth process
  USER_VIEWED_LOGIN_PAGE: 'USER_VIEWED_LOGIN_PAGE',
  USER_VIEWED_CHECKOUT_LOGIN_PAGE: 'USER_VIEWED_CHECKOUT_LOGIN_PAGE',
  USER_CLICKED_LOGIN_BUTTON: 'USER_CLICKED_LOGIN_BUTTON',
  USER_CLICKED_REGISTER_FROM_LOGIN: 'USER_CLICKED_REGISTER_FROM_LOGIN',
  USER_CLICKED_FORGOT_PASSWORD: 'USER_CLICKED_FORGOT_PASSWORD',

  USER_VIEWED_REGISTRATION_PAGE: 'USER_VIEWED_REGISTRATION_PAGE',
  USER_VIEWED_CHECKOUT_REGISTRATION_PAGE: 'USER_VIEWED_CHECKOUT_REGISTRATION_PAGE',
  USER_CLICKED_LOGIN_FROM_REGISTER: 'USER_CLICKED_LOGIN_FROM_REGISTER',
  USER_CLICKED_RESEND_CODE: 'USER_CLICKED_RESEND_CODE',

  USER_VIEWED_CONFIRM_EMAIL_PAGE: 'USER_VIEWED_CONFIRM_EMAIL_PAGE',
  USER_VIEWED_CHECKOUT_CONFIRM_EMAIL_PAGE: 'USER_VIEWED_CHECKOUT_CONFIRM_EMAIL_PAGE',
  USER_CLICKED_CHANGE_EMAIL_REGISTER: 'USER_CLICKED_CHANGE_EMAIL_REGISTER',
  USER_CLICKED_VERIFY_EMAIL_REGISTER: 'USER_CLICKED_VERIFY_EMAIL_REGISTER',

  USER_VIEWED_FORGOT_PASSWORD_PAGE: 'USER_VIEWED_FORGOT_PASSWORD_PAGE',
  USER_VIEWED_CHECKOUT_FORGOT_PASSWORD_PAGE: 'USER_VIEWED_CHECKOUT_FORGOT_PASSWORD_PAGE',
  USER_CLICKED_SEND_CODE_FORGOTPASSWORD: 'USER_CLICKED_SEND_CODE_FORGOTPASSWORD',
  USER_CLICKED_UPDATE_PASSWORD: 'USER_CLICKED_UPDATE_PASSWORD',

  USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_PRIVACY_POLICY_LINK',
  USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_AND_CONDITIONS_LINK: 'USER_CLICKED_GOOGLE_RECAPTCHA_TERMS_AND_CONDITIONS_LINK',
  GOOGLE_RECAPTCHA_ERROR: 'GOOGLE_RECAPTCHA_ERROR',
  GOOGLE_RECAPTCHA_CANCEL: 'GOOGLE_RECAPTCHA_CANCEL',
  USER_VIEWED_VERIFY_EMAIL_PAGE: 'USER_VIEWED_VERIFY_EMAIL_PAGE',

  // about
  USER_VIEWED_ABOUT_PAGE: 'USER_VIEWED_ABOUT_PAGE',
  USER_CLICKED_ABOUT_CONTACT_US_LINK: 'USER_CLICKED_ABOUT_CONTACT_US_LINK',

  // products
  VIEWED_DEDICATED_PRODUCT_PAGE: 'VIEWED_DEDICATED_PRODUCT_PAGE',
  USER_CLICKED_PRODUCT_CHECKOUT_NOW_BUTTON: 'USER_CLICKED_PRODUCT_CHECKOUT_NOW_BUTTON',
  USER_CLICKED_PRODUCT_VIEW_ALL_PRODUCTS_BUTTON: 'USER_CLICKED_PRODUCT_VIEW_ALL_PRODUCTS_BUTTON',
  USER_UPDATED_PRODUCT_QUANTITY: 'USER_UPDATED_PRODUCT_QUANTITY',
  USER_CLICKED_QUANTITY_BUTTON: 'USER_CLICKED_QUANTITY_BUTTON',
  USER_CLICKED_PRODUCT_VIEW_ALL_PRODUCTS_LINK: 'USER_CLICKED_PRODUCT_VIEW_ALL_PRODUCTS_LINK',
  USER_REMOVED_ITEM_FROM_CART: 'USER_REMOVED_ITEM_FROM_CART',
  USER_CLICKED_DENOMINATION_BUTTON: 'USER_CLICKED_DENOMINATION_BUTTON',
  USER_CLICKED_ADD_TO_CART_BUTTON: 'USER_CLICKED_ADD_TO_CART_BUTTON',

  // legal
  USER_VIEWED_LEGAL_DOC_PAGE: 'USER_VIEWED_LEGAL_DOC_PAGE',
  USER_CLICKED_LANDING_HERO_CTA_BUTTON:
    'USER_CLICKED_LANDING_HERO_CTA_BUTTON',

  // adwords landing
  USER_VIEWED_ADWORDS_LANDING_PAGE: 'USER_VIEWED_ADWORDS_LANDING_PAGE',
  USER_CLICKED_ADWORDS_LANDING_HERO_CTA_BUTTON:
    'USER_CLICKED_ADWORDS_LANDING_HERO_CTA_BUTTON',
  USER_CLICKED_ADWORDS_LANDING_BODY_CTA_BUTTON:
    'USER_CLICKED_ADWORDS_LANDING_BODY_CTA_BUTTON',

  // cart
  USER_CLICKED_CART_REMOVE_ITEM: 'USER_CLICKED_CART_REMOVE_ITEM',
  USER_VIEWED_SHOPPING_CART: 'USER_VIEWED_SHOPPING_CART',
  USER_CLICKED_CART_CHECKOUT_BUTTON: 'USER_CLICKED_CART_CHECKOUT_BUTTON',
  USER_CLICKED_HELP_ARTICLE_DIRECT_LINK: 'USER_CLICKED_HELP_ARTICLE_DIRECT_LINK',

  // settings
  USER_VIEWED_SETTINGS_PAGE: 'USER_VIEWED_SETTINGS_PAGE',
  USER_CLICKED_UPDATE_EMAIL_BUTTON: 'USER_CLICKED_UPDATE_EMAIL_BUTTON',
  USER_CLICKED_CHANGE_EMAIL_BUTTON: 'USER_CLICKED_CHANGE_EMAIL_BUTTON',

  // review
  USER_VIEWED_REVIEW_CART_PAGE: 'USER_VIEWED_REVIEW_CART_PAGE',
  USER_VIEWED_EMPTY_REVIEW_CART_PAGE: 'USER_VIEWED_EMPTY_REVIEW_CART_PAGE',
  USER_CLICKED_CONTINUE_SHOPPING_BUTTON: 'USER_CLICKED_CONTINUE_SHOPPING_BUTTON',
  USER_CLICKED_PURCHASE_BUTTON: 'USER_CLICKED_PURCHASE_BUTTON',
  USER_CLICKED_BROWSE_PRODUCTS_BUTTON: 'USER_CLICKED_BROWSE_PRODUCTS_BUTTON',
  USER_CLICKED_PAYSAFECARD_TERMS_LINK: 'USER_CLICKED_PAYSAFECARD_TERMS_LINK',

  // payment
  USER_VIEWED_PAYMENT_PAGE: 'USER_VIEWED_PAYMENT_PAGE',
  USER_CLICKED_CANCEL_PAYMENT_BUTTON: 'USER_CLICKED_CANCEL_PAYMENT_BUTTON',
  USER_CLICKED_PAY_NOW_BUTTON: 'USER_CLICKED_PAY_NOW_BUTTON',
  USER_CLICKED_PAYMENT_GAMEPLAYSHOP_TERMS_LINK: 'USER_CLICKED_PAYMENT_GAMEPLAYSHOP_TERMS_LINK',
  USER_DETECTED_ON_FRAUDLIST: 'USER_DETECTED_ON_FRAUDLIST',
  USER_HIT_NO_RETRY_PAYMENT_RESULT_CODE: 'USER_HIT_NO_RETRY_PAYMENT_RESULT_CODE',
  PAYSAFE_ERROR: 'PAYSAFE_ERROR',
  PAYSAFE_TOKENIZE_SUCCESS: 'PAYSAFE_TOKENIZE_SUCCESS',

  // kyc
  USER_VIEWED_KYC_PERSONAL_INFO_PAGE: 'USER_VIEWED_KYC_PERSONAL_INFO_PAGE',
  USER_VIEWED_KYC_IDENTIFICATION_PAGE: 'USER_VIEWED_KYC_IDENTIFICATION_PAGE',
  USER_CLICKED_KYC_PERSONAL_INFO_PROCEED_BUTTON: 'USER_CLICKED_KYC_PERSONAL_INFO_PROCEED_BUTTON',
  USER_CLICKED_KYC_IDENTIFICATION_PROCEED_BUTTON: 'USER_CLICKED_KYC_IDENTIFICATION_PROCEED_BUTTON',
  USER_CLICKED_KYC_GAMEPLAYSHOP_TERMS_LINK: 'USER_CLICKED_KYC_GAMEPLAYSHOP_TERMS_LINK',
  USER_CLICKED_UNABLE_TO_RECEIVE_TEXT_LINK: 'USER_CLICKED_UNABLE_TO_RECEIVE_TEXT_LINK',
  USER_SELECTED_ALTERNATIVE_PHONE_VERIFY_METHOD: 'USER_SELECTED_ALTERNATIVE_PHONE_VERIFY_METHOD',
  USER_CLICKED_SEND_PHONE_PIN_BUTTON: 'USER_CLICKED_SEND_PHONE_PIN_BUTTON',
  USER_CLICKED_VERIFY_PHONE_PIN_BUTTON: 'USER_CLICKED_VERIFY_PHONE_PIN_BUTTON',
  USER_VERIFIED_PHONE: 'USER_VERIFIED_PHONE',
  USER_CLICKED_RESEND_PHONE_PIN_BUTTON: 'USER_CLICKED_RESEND_PHONE_PIN_BUTTON',
  USER_CLICKED_BROWSE_FOR_FILE_BUTTON: 'USER_CLICKED_BROWSE_FOR_FILE_BUTTON',
  USER_CLICKED_BROWSE_FOR_FILE_INPUT: 'USER_CLICKED_BROWSE_FOR_FILE_INPUT',

  // confirmation
  USER_VIEWED_CONFIRMATION_PAGE: 'USER_VIEWED_CONFIRMATION_PAGE',
  USER_CLICKED_COPY_PIN: 'USER_CLICKED_COPY_PIN',
  USER_CLICKED_PRINT_PIN: 'USER_CLICKED_PRINT_PIN',
  USER_CLICKED_ACCESS_EGIFT_LINK: 'USER_CLICKED_ACCESS_EGIFT_LINK',
  USER_CLICKED_PRINT: 'USER_CLICKED_PRINT_PIN',
  USER_CLICKED_CONFIRMATION_DONE_BUTTON: 'USER_CLICKED_CONFIRMATION_DONE_BUTTON',
  USER_CLICKED_STAR_RATING: 'USER_CLICKED_STAR_RATING',
  USER_CLICKED_RATING_SUBMIT_BUTTON: 'USER_CLICKED_RATING_SUBMIT_BUTTON',
  USER_VIEWED_RATING_THANKYOU_PAGE: 'USER_VIEWED_RATING_THANKYOU_PAGE',

  // general
  API_ERROR: 'API_ERROR',
  API_SUCCESS: 'API_SUCCESS',
  USER_VIEWED_MAINTENANCE_PAGE: 'USER_VIEWED_MAINTENANCE_PAGE',
  USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON: 'USER_CLICKED_MAINTENANCE_ANIMATION_BUTTON',
  API_MAINTENANCE_MODE: 'API_MAINTENANCE_MODE',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  USER_VIEWED_ALERT: 'USER_VIEWED_ALERT',
  USER_CLICKED_BACK_BUTTON: 'USER_CLICKED_BACK_BUTTON',
  USER_VIEWED_ERROR_PAGE: 'USER_VIEWED_ERROR_PAGE',
  USER_CLICKED_ERROR_PAGE_BUTTON: 'USER_CLICKED_ERROR_PAGE_BUTTON',
  USER_CLICKED_VETTED_STATUS_PAGE_BUTTON: 'USER_CLICKED_VETTED_STATUS_PAGE_BUTTON',
  USER_VIEWED_VETTED_STATUS_PAGE: 'USER_VIEWED_VETTED_STATUS_PAGE',
  USER_ENTERED_INVALID_VALUE: 'USER_ENTERED_INVALID_VALUE',
  USER_CLICKED_NAVBAR_LINK: 'USER_CLICKED_NAVBAR_LINK',
  USER_CLICKED_FOOTER_LINK: 'USER_CLICKED_FOOTER_LINK',
  USER_VIEWED_CART_LOCKED_ALERT: 'USER_VIEWED_CART_LOCKED_ALERT',
  USER_VIEWED_SKYSCRAPER_PAGE: 'USER_VIEWED_SKYSCRAPER_PAGE',
  USER_VIEWED_SUNSET_PAGE: 'USER_VIEWED_SUNSET_PAGE',
  USER_CLICKED_SUNSET_PAGE_CONTACT_US_BUTTON: 'USER_CLICKED_SUNSET_PAGE_CONTACT_US_BUTTON',
};
