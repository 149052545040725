/* eslint-disable no-underscore-dangle */
import { useMemo } from 'react';
import { createStore } from 'redux';
import { Pool } from '../utils/Cognito';
import reducers from './reducers';
import { authInitialState } from './reducers/AuthReducer';

let store;

const initialState = {
  auth: reducers.auth,
};

function initStore(preloadedState = initialState) {
  return createStore(reducers, preloadedState);
}

export function initializeStore(preloadedState) {
  let _store = store ?? initStore(preloadedState && {
    ...preloadedState,
    auth: {
      ...{ user: Pool.getCurrentUser() },
      ...preloadedState.auth,
    },
  });

  // After navigating to a page with an initial Redux state, merge that state
  // with the current state in the store, and create a new store
  if (preloadedState && store) {
    const prevState = store.getState();
    _store = initStore({
      auth: {
        ...authInitialState,
        ...{ user: Pool.getCurrentUser() },
        ...prevState.auth,
        ...preloadedState.auth,
      },
      cart: {
        ...prevState.cart,
        ...preloadedState.cart,
      },
    });
    // Reset the current store
    store = undefined;
  }

  // For SSG and SSR always create a new store
  if (typeof window === 'undefined') return _store;
  // Create the store once in the client
  if (!store) store = _store;

  return _store;
}

export function useStore(preloadedState) {
  const _store = useMemo(
    () => initializeStore(preloadedState),
    [preloadedState]
  );
  return _store;
}
