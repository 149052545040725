export const LANGUAGES = {
  EN: 'en',
};

export const PRODUCT_FILTERS = [
  { value: 'Payment Voucher', label: 'Landing_Filter_PaymentVoucher', icon: 'money-check-dollar' },
  { value: 'Gaming Card', label: 'Landing_Filter_GamingCard', icon: 'gamepad' },
  { value: 'Gift Card', label: 'Landing_Filter_GiftCard', icon: 'gift-card' },
];

export const AUTH_API = {
  LOGIN: 'Authenticate',
  REGISTER: 'SignUp',
  CONFIRM_EMAIL: 'ConfirmSignUp',
  RESEND_CONFIRM_EMAIL: 'ResendConfirmationCode',
  FORGOT_PASSWORD: 'ForgotPassword',
  CONFIRM_FORGOT_PASSWORD: 'ConfirmForgotPassword',
  UPDATE_EMAIL: 'UpdateEmail',
  CONFIRM_UPDATE_EMAIL: 'ConfirmEmail',
  VERIFY_EMAIL: 'VerifyEmail',
  RESEND_VERIFICATION_CODE: 'ResendVerificationCode',
};

export const AUTH_STEP = {
  LOGIN: 'Login',
  REGISTER: 'Register',
  CONFIRM_EMAIL: 'Confirm Email',
  FORGOT_PASSWORD: 'Forgot Password',
  VERIFY_EMAIL: 'Verify Email',
};

export const CHECKOUT_STEPS = {
  REVIEW: 'Review',
  KYC_PERSONAL_INFO: 'KYC Personal Info',
  KYC_IDENTIFICATION: 'KYC Identification',
  PAYMENT: 'Payment',
  CONFIRMATION: 'Confirmation',
  VETTED_STATUS: 'Vetted Status',
};

export const KYC_API = {
  GET_CUSTOMER_INFO: 'GetCustomerInfo',
  SEND_PHONE_PIN: 'SendPhonePin',
  CONFIRM_PHONE_PIN: 'ConfirmPhonePin',
  KYC_PERSONAL_INFO: 'KycPersonalInfo',
  KYC_IDENTIFICATION: 'KycIdentification',
};

export const PHONE_VERIFY_METHOD = {
  VOICE: 'Voice',
  TEXT: 'Text',
};

export const PHONE_VERIFY_STEP = {
  NOT_SENT: 'Not Sent',
  SENT_PIN: 'Sent Pin',
  VERIFIED: 'Verifed',
};

export const CHANGE_EMAIL_STEP = {
  UNINITIATED: 'Uninitiated',
  INITIATED: 'Initiated',
  CHANGED_EMAIL: 'Changed Email',
};

export const PAYMENT_API = {
  PROCESS_PAYMENT: 'ProcessPayment',
  PAYMENT_HANDLE_FAILED: 'PaymentHandleFailed',
};

export const OTHER_API = {
  MAINTENANCE_MODE: 'MaintenanceMode',
};

export const USER_STATUS = {
  KYC_REQUIRED: 'KYCRequired',
  KYC_NOT_REQUIRED: 'KYCNotRequired',
  USER_BLOCKED: 'Blocked',
  CONFIRM_PHONE_PIN: 'ConfirmPhonePin',
  KYC_REGISTRATION: 'KYCRegistration',
};

export const ORDER_FEE = 0.99;

export const MAX_DENOM_QUANTITY = 10;
