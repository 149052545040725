import { Provider } from 'react-redux';
import { setLanguage, setTranslations, getLanguage } from 'react-switch-lang';
import Script from 'next/script';
import { useRouter } from 'next/router';
import aa from 'search-insights';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
/* fas */ import { faChevronLeft, faCheck, faCheckCircle, faExclamationCircle, faMinus, faPlus, faBars, faTimes, faCalendarAlt, faDesktop, faCog, faPause, faPlay } from '@fortawesome/pro-solid-svg-icons';
/* fal */ import { faEye, faEyeSlash, faMagnifyingGlass, faMoneyCheckDollar, faGamepad, faGiftCard, faCopy, faPrint } from '@fortawesome/pro-light-svg-icons';
/* fat */ import { faLockKeyhole, faHandshake, faTruckFast, faHandsHoldingDollar, faMoneyCheckDollar as fatMoneyCheckDollar, faCartCircleCheck, faUserCircle, faCreditCardFront, faBoxCheck } from '@fortawesome/pro-thin-svg-icons';
/* far */ import { faChevronDown, faShoppingCart, faMagnifyingGlass as farMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons';
/* fad */ import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { useStore } from '../redux/Store';
import { loadState as loadCartState } from '../redux/reducers/CartReducer';
import Redirect from '../components/Redirect';
// import NotFound from './404';
import { isProduction } from '../utils/HostingEnv';
import '../public/fonts/style.css';
import '../styles/bootstrap.scss';
import '../styles/Global.scss';
import '../styles/Pace.scss';
import '../styles/Legal.scss';
import '../styles/SiteMap.scss';
import '../styles/AdWords.scss';
import '../styles/Algolia.scss';
import '../styles/Forms.scss';
import '../styles/Checkout.scss';
import '../styles/Footer.scss';
import '../styles/SkipToContent.scss';
import en from '../languages/English.json';
import { LANGUAGES } from '../utils/Constants';
import { useLocalStorageSync } from '../utils/Helpers';
import { loadVettedStatus } from '../redux/reducers/AuthReducer';

config.autoAddCss = false;
/* eslint-disable function-paren-newline, function-call-argument-newline, max-len */
library.add(
  /* fas */ faChevronLeft, faCheck, faCheckCircle, faExclamationCircle, faMinus, faPlus, faBars, faTimes, faCalendarAlt, faDesktop, faCog, faPause, faPlay,
  /* fal */ faEye, faEyeSlash, faMagnifyingGlass, faMoneyCheckDollar, faGamepad, faGiftCard, faCopy, faPrint,
  /* fat */ faLockKeyhole, faHandshake, faTruckFast, faHandsHoldingDollar, fatMoneyCheckDollar, faCartCircleCheck, faUserCircle, faCreditCardFront, faBoxCheck,
  /* far */ faChevronDown, faShoppingCart, farMagnifyingGlass,
  /* fad */ faSpinnerThird
);
/* eslint-enable function-paren-newline, function-call-argument-newline, max-len */

setTranslations({ en });

// init Algolia Analytics
aa('init', {
  appId: process.env.NEXT_PUBLIC_ALGOLIA_APP_ID,
  apiKey: process.env.NEXT_PUBLIC_ALGOLIA_API_KEY,
  useCookie: true, // Anonymous users
});

// const pages = [
//   '/privacy-policy',
//   '/accessibility-statement',
//   '/terms-of-use',
//   '/pay-with-paysafecard',
//   '/pay-to-play',
//   '/paysafecard-sports',
//   '/products',
//   '/about',
//   '/login',
//   '/register',
//   '/checkout',
//   '/settings',
//   '/vetted-status',
//   '/maintenance',
//   '/s/growing-gaming-market',
// ];
// const hiddenPages = [
//   '/[lang]/logout',
//   '/[lang]/forgot-password',
//   '/[lang]/error',
//   '/[lang]/verify-email',
//   '/[lang]/site-map',
// ];

const preloadedState = typeof window === 'undefined' ? undefined : {
  cart: loadCartState(),
  auth: { vettedStatus: loadVettedStatus() },
};

function App({ Component, pageProps }) {
  const store = useStore(preloadedState);
  useLocalStorageSync(store);

  const router = useRouter();
  let {
    query: { lang },
  } = router;
  const { asPath: path, pathname } = router;

  if (pathname === '/404') lang = getLanguage();

  try {
    if (typeof localStorage === 'undefined') {
      lang = lang || LANGUAGES.EN;
    } else if (lang) {
      // lang in query takes precedence over saved lang in localStorage
      localStorage.setItem('language', lang);
    } else {
      // if there's no lang passed in from the URL, try to retrieve saved lang
      lang = localStorage.getItem('language');

      // if there's no saved lang, save en
      if (!Object.values(LANGUAGES).includes(lang)) {
        lang = LANGUAGES.EN;
        localStorage.setItem('language', LANGUAGES.EN);
      }
    }
  } catch (err) {
    // try-catch just here to prevent exception in Sentry
  }

  setLanguage(lang);

  return (
    <>
      <Provider store={store}>
        {path !== '/en/shutdown' ? (
          <Redirect path="/en/shutdown" asIs />
        ) : (
          <Component {...pageProps} lang={lang} />
        )}
      </Provider>
      {isProduction && (
        <>
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=G-G132D8ZQNQ"
            strategy="afterInteractive"
          />
          <Script
            src="https://www.googletagmanager.com/gtag/js?id=AW-1036332117"
            strategy="afterInteractive"
          />
        </>
      )}
    </>
  );
}

export default App;
