import { t } from 'react-switch-lang';
import { isLocal } from './HostingEnv';

/**
 * Creates an element with a specified role and appends it to an existing element.
 * These alerts are used by screen readers to notify users something has happened.
 *
 * role = 'alert' - Used for negative announcements (errors, warnings, etc)
 * role = 'status' - Used for positive announcements (successful form submission, etc)
 *
 * @param {any} msg
 * The message that will be read aloud.
 * @param {any} appendTo
 * Where the element will be attached to.
 * @param {any} role
 * Role type (alert or status preferably)
 */
export const setScreenReaderAlert = (
  msg,
  role = 'alert',
  appendTo = document.body
) => {
  if (!msg) return;

  const screenreaderAlert = document.createElement('span');
  screenreaderAlert.className = 'visuallyHidden';
  screenreaderAlert.setAttribute('role', role);
  const txtNode = document.createTextNode(msg);
  screenreaderAlert.appendChild(txtNode);
  appendTo.append(screenreaderAlert);

  if (isLocal) {
    // eslint-disable-next-line no-console
    console.log(`Role with ${role} saying ${msg}`);
  }
};

export const moveFocusTo = (id) => {
  const el = document.getElementById(id);
  if (el) {
    el.setAttribute('tabIndex', -1);
    el.focus();
  }
};

export const setPageTitle = (title) => {
  if (typeof document !== 'undefined') {
    const e = document.getElementById('__next-route-announcer__');
    if (e) e.textContent = `${title} | ${t('OfficialSiteName')}`;
  }
};

export const replaceProductString = (text, denom, name, quantity = '') => text.replace('xDenom', denom)
  .replace('xName', name).replace('xQuantity', quantity);
