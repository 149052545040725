import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { getLanguage } from 'react-switch-lang';

export default function Redirect({ path, asIs }) {
  const router = useRouter();
  useEffect(() => {
    router.replace(asIs ? path : `/${getLanguage()}${path}`.replace(/\/*$/, ''));
  }, [path, router, asIs]);
  return null;
}
