export const types = {
  SET_EMAIL: 'SET_EMAIL',
  SET_USER: 'SET_USER',
  SET_SESSION: 'SET_SESSION',
  SET_CUSTOMER_ID: 'SET_CUSTOMER_ID',
  SET_VETTED_STATUS: 'SET_VETTED_STATUS',
  SET_EMAIL_VERIFIED: 'SET_EMAIL_VERIFIED',
  CLEAR_SESSION: 'CLEAR_SESSION',
};

export const setEmail = (email) => ({
  type: types.SET_EMAIL,
  payload: email,
});

export const setSession = (session) => ({
  type: types.SET_SESSION,
  payload: session,
});

export const setUser = (user) => ({
  type: types.SET_USER,
  payload: user,
});

export const setCustomerID = (customerID) => ({
  type: types.SET_CUSTOMER_ID,
  payload: customerID,
});

export const setVettedStatus = (status, msg) => ({
  type: types.SET_VETTED_STATUS,
  payload: { status, msg },
});

export const setEmailVerified = (value) => ({
  type: types.SET_EMAIL_VERIFIED,
  payload: value,
});

export const clearSession = () => ({
  type: types.CLEAR_SESSION,
});
