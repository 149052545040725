import { useDispatch, useSelector, useStore } from 'react-redux';
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js';
import { t, getLanguage } from 'react-switch-lang';
import { useRouter } from 'next/router';
import { captureException, captureMessage } from '@sentry/nextjs';
import { setSession, clearSession, setUser, setEmailVerified } from '../redux/actions/AuthActions';
import { isDevelopment, isProduction } from './HostingEnv';
import { events, logAmpEvent } from './Amplitude';
import { AUTH_API, KYC_API, OTHER_API, PAYMENT_API } from './Constants';

export const Pool = new CognitoUserPool({
  UserPoolId: process.env.NEXT_PUBLIC_COGNITO_USER_POOL_ID,
  ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
});

function log(...args) {
  // eslint-disable-next-line no-console
  if (isDevelopment) console.log(...args);
}

function warn(...args) {
  // eslint-disable-next-line no-console
  if (isDevelopment) console.warn(...args);
}

function cognitoLog(callName, data, success = true) {
  if (success) {
    log(`Cognito: %c${callName}`, 'background-color: plum;color:black;', data);
  } else {
    warn(`Cognito: %c${callName}`, 'background-color: plum;color:black;', data);
  }
}

function apiLog(callName, data) {
  log(`API: %c${callName}`, 'background-color: greenyellow;color:black;', data);
}

// custom error messages are returned in format "Error: error msg here"
const handleCognitoCustomError = (msg) => {
  const customErrorIdentifier = '[ERROR]';
  if (!msg) return false;
  if (msg.length < 6) return false;
  const customErrIndex = msg.indexOf(customErrorIdentifier);
  if (customErrIndex > -1) {
    return msg.substr(customErrIndex + customErrorIdentifier.length + 1);
  }
  return t('Error_Default');
};

function parseCognitoError(err, call) {
  // check language file for any custom error message overrides
  const langFileError = t(`Error_Cognito_${err.code}`);
  if (langFileError !== `Error_Cognito_${err.code}`) return langFileError;

  // special handling
  switch (err.code) {
    case 'NotAuthorizedException':
      if (call === AUTH_API.LOGIN) return t('Error_Cognito_IncorrectUsernamePassword');
      return t('Error_Cognito_NotAuthorized');
    case 'LimitExceededException':
    case 'TooManyFailedAttemptsException':
    case 'TooManyRequestsException':
      return t('Error_Cognito_Velocity');
    default:
      return handleCognitoCustomError(err.message);
  }
}

/**
 * Generates a cognito error handler callback that
 * takes in the error object from cognito and handles it accordingly by
 * calling either a custom handler or the generic handler.
 * The generic handler will parse the error object and
 * pass in the parsed error message into the errMsgCallback,
 * while also logging the API error event to Amplitude.
 *
 * @param {string} call name of the cognito call
 * @param {(parsedMessage:string)=>*} [errMsgCallback]
 * callback to run as part of the generic handler; will be passed in the parsed error message
 * @param {{ErrorCode:(error:{code:string;message:string;name:string})=>*}} [customHandlers]
 * key-value pairs for specific error handling,
 * where the key is the error code and the value is the custom handler for that error code
 * @returns {(error:{code:string;message:string;name:string})=>*}
 */
export function genCognitoErrHandler(call, errMsgCallback, customHandlers) {
  return (err) => {
    if (customHandlers?.[err.code]) {
      // run custom handler
      customHandlers[err.code](err);
    } else {
      // run generic handler
      const errMsg = parseCognitoError(err, call);
      logAmpEvent(events.API_ERROR, {
        Call: `Cognito: ${call}`,
        'Result Code': err.code,
        Description: errMsg,
      });
      errMsgCallback?.(errMsg);
    }

    return false; // needed for ResendEmail component
  };
}

// custom error handler for General api calls
export function handleAPIError(res, errMsgCallback) {
  // run generic handler
  if (res.Result !== 0) {
    if (res.Result !== -3) errMsgCallback?.(res.Description || t('Error_Default'));
    return false;
  }
  return true;
}

function callbackWrapper(callName, resolve, reject) {
  return (err, result) => {
    if (err) {
      cognitoLog(callName, err, false);
      reject(err);
    } else {
      cognitoLog(callName, result);
      resolve(result);
    }
  };
}

export async function getSessionAndAttr() {
  return new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(callbackWrapper('getSession', (session) => {
        user.getUserAttributes(callbackWrapper('getUserAttributes', (attributes) => {
          resolve([session, attributes]);
        }, reject));
      }, reject));
    } else {
      reject();
    }
  });
}

function handleFetchError(err) {
  let mockResultCode = -1; // general fetch failures (network failures, etc)
  if (err) {
    if (err.message === 'Refresh Token has expired' || err.message === 'Refresh Token has been revoked') {
      mockResultCode = -2; // expired session, requires login again
    } else if (isProduction) {
      if (err instanceof Error) captureException(err);
      else captureMessage(JSON.stringify(err));
    }
  }
  return {
    Result: mockResultCode,
    Description: t('Error_Default', null, getLanguage()),
  };
}

export function useCognito() {
  const user = useSelector((state) => state.auth.user);
  const store = useStore();
  // always use the current session in the store instead of relying on updates from useSelector
  const getSession = () => store.getState().auth.session;
  const emailVerifiedState = useSelector((state) => state.auth.emailVerified);
  const dispatch = useDispatch();
  const router = useRouter();
  const onBeforeRedirect = typeof window === 'undefined' ? undefined : window.disableFormExitConfirm;

  // General API Fetch Call
  async function makeCall(call, data = {}) {
    // Redirect user to verify email page if they have not verified their new eamil
    if (call !== AUTH_API.CONFIRM_UPDATE_EMAIL && emailVerifiedState === 'false') {
      await Promise.resolve(onBeforeRedirect?.());
      router.push(`/${getLanguage()}/verify-email`);
      // Return mock error
      return {
        Result: -3, // user not verified
        Description: t('Error_Default', null, getLanguage()),
      };
    }
    const reqBody = { ...data, Platform: 'Web' };
    const url = process.env.NEXT_PUBLIC_API_URI + call;

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: getSession().getIdToken().getJwtToken(),
        'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
      },
      body: JSON.stringify(reqBody),
    }).then(async (res) => {
      const respBody = await res.json();

      apiLog(call, { url, reqBody, respBody, respStatus: res.status });

      // expired token
      if (res.status === 401 && respBody.message === 'The incoming token has expired') {
        return (new Promise((resolve, reject) => {
          user.refreshSession(getSession().getRefreshToken(), (err, sess) => {
            if (err) {
              cognitoLog('refreshSession', err, false);
              reject(err);
            } else {
              cognitoLog('refreshSession', sess);
              dispatch(setSession(sess));

              fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  Authorization: sess.getIdToken().getJwtToken(),
                  'x-api-key': process.env.NEXT_PUBLIC_API_KEY,
                },
                body: JSON.stringify(reqBody),
              }).then(async (res2) => {
                const res2Body = await res2.json();
                apiLog(call, { url, reqBody, respBody: res2Body, respStatus: res2.status });
                resolve(res2Body);
              }).catch(() => reject());
            }
          });
        }));
      }

      if (res.status === 413) {
        return {
          Result: 413, // Request Too Long
          Description: t('Error_Default', null, getLanguage()),
        };
      }

      if (res.status !== 200) {
        const err = new Error(`${JSON.stringify(respBody)}`);
        err.name = res.status;
        return {
          ...handleFetchError(err),
          Result: res.status, // log http status to Amplitude API_ERROR event
        };
      }

      return respBody;
    }).catch(handleFetchError);

    if (response.Result === 0) {
      logAmpEvent(events.API_SUCCESS, { Call: call });
    } else if (response.Result === -2) {
      await Promise.resolve(onBeforeRedirect?.());
      router.push(`/${getLanguage()}/logout`);
    } else if (response.Result === 4) {
      logAmpEvent(events.API_MAINTENANCE_MODE, {
        Call: `API: ${call}`,
        'Result Code': response.Result,
        Description: response.Description,
        MaintenanceMode: response.MaintenanceMode,
      });
      if (call !== OTHER_API.MAINTENANCE_MODE) {
        await Promise.resolve(onBeforeRedirect?.());
        router.push(`/${getLanguage()}/maintenance`);
      }
    } else {
      logAmpEvent(events.API_ERROR, {
        Call: `API: ${call}`,
        'Result Code': response.Result,
        Description: response.Description,
      });
    }

    return response;
  }

  return {
    authenticate: async (Username, Password, ValidationData) => (new Promise((resolve, reject) => {
      window.Pace?.restart();

      const u = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password, ValidationData });

      u.authenticateUser(authDetails, {
        onSuccess: (sess) => {
          cognitoLog('authenticateUser', sess);
          u.getUserAttributes(callbackWrapper('getUserAttributes', (attributes) => {
            const emailVerified = attributes.find((attr) => attr.Name === 'email_verified')?.Value;
            dispatch(setEmailVerified(emailVerified));
            dispatch(setUser(u));
            dispatch(setSession(sess));
            resolve(u);
          }, reject));
        },
        onFailure: (err) => {
          cognitoLog('authenticateUser', err, false);
          reject(err);
        },
      });
    })),
    /** @param {string} [redirectTo] path to redirect to after sign out (e.g. `/login`) */
    signOut: async (redirectTo) => (new Promise((resolve) => {
      window.Pace?.restart();
      if (!user) {
        dispatch(clearSession());
        resolve();
      } else {
        user.signOut(() => {
          dispatch(clearSession());
          if (redirectTo) router.push(`/${getLanguage()}${redirectTo}`);
          resolve();
        });
      }
    })),

    // Signup
    /**
     * Creates a CognitoUserAttribute object given the name and value
     * @param {string} name
     * attribute name as displayed on Cognito Console
     * ex) email, custom:reg_date, etc.
     * @param {string} value
     */
    attribute: (name, value) => new CognitoUserAttribute({ Name: name, Value: value }),

    /**
     * @param {string} email
     * @param {string} password
     * @param {CognitoUserAttribute[]} attrArr array of attributes to add for this user
     */
    signUp: async (
      email,
      password,
      validationData,
      clientMetadata
    ) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      Pool.signUp(email, password, [], validationData, callbackWrapper('signUp', resolve, reject), clientMetadata);
    })),

    // Confirm
    /**
     * @param {string} email email of the user confirming registration
     * @param {string} code verification code received by email
     */
    confirmRegistration: async (email, code) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).confirmRegistration(code, true, callbackWrapper('confirmRegistration', resolve, reject));
    })),
    resendConfirmationCode: async (email) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).resendConfirmationCode(callbackWrapper('resendConfirmationCode', resolve, reject));
    })),

    verifyEmail: async (code) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      user.verifyAttribute('email', code, {
        onSuccess: (result) => {
          cognitoLog('verifyEmail', result);
          // Refresh user session after email verify
          user.refreshSession(getSession().getRefreshToken(), (err, sess) => {
            if (err) {
              cognitoLog('refreshSession', err, false);
              reject(err);
            } else {
              cognitoLog('refreshSession', sess);
              dispatch(setSession(sess));
              resolve(result);
            }
          });
        },
        onFailure: (err) => {
          cognitoLog('verifyEmail', err, false);
          reject(err);
        },
      });
    })),
    resendVerificationCode: async () => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      user.getAttributeVerificationCode('email', {
        onSuccess: (result) => {
          cognitoLog('resendVerificationCode', result);
          resolve(result);
        },
        onFailure: (err) => {
          cognitoLog('resendVerificationCode', err, false);
          reject(err);
        },
      });
    })),

    // forgot password flow
    resetPassword: async (email) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).forgotPassword({
        onSuccess: (data) => {
          cognitoLog('resetPassword', data);
          resolve(data);
        },
        onFailure: (err) => {
          cognitoLog('resetPassword', err, false);
          reject(err);
        },
      });
    })),
    confirmNewPassword: async (email, code, newPassword) => (new Promise((resolve, reject) => {
      window.Pace?.restart();
      new CognitoUser({
        Username: email,
        Pool,
      }).confirmPassword(code, newPassword, {
        onSuccess: (data) => {
          cognitoLog('confirmNewPassword', data);
          resolve(data); // just returns 'SUCCESS'
        },
        onFailure: (err) => {
          cognitoLog('confirmNewPassword', err, false);
          reject(err);
        },
      });
    })),
    updateEmail: async (NewEmail) => makeCall(AUTH_API.UPDATE_EMAIL, { NewEmail }),
    confirmEmail: async () => makeCall(AUTH_API.CONFIRM_UPDATE_EMAIL),

    // KYC API Calls
    getCustomerInfo: async () => makeCall(KYC_API.GET_CUSTOMER_INFO),

    sendPhonePin: async (details) => makeCall(KYC_API.SEND_PHONE_PIN, details),
    confirmPhonePin: async (details) => makeCall(KYC_API.CONFIRM_PHONE_PIN, details),

    KycPersonalInfo: async (details) => makeCall(KYC_API.KYC_PERSONAL_INFO, details),
    KycIdentification: async (details) => makeCall(KYC_API.KYC_IDENTIFICATION, details),

    // Payment API Calls
    ProcessPayment: async (details) => makeCall(PAYMENT_API.PROCESS_PAYMENT, details),
    PaymentHandleFailed: async (error) => makeCall(PAYMENT_API.PAYMENT_HANDLE_FAILED, {
      Message: error.message,
      Code: error.code,
      PaymentResponse: JSON.stringify(error),
    }),

    checkMaintenanceMode: async () => makeCall(OTHER_API.MAINTENANCE_MODE),
  };
}
