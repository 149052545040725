import { types } from '../actions/AuthActions';

export const authInitialState = {
  user: null,
  session: null,
  email: null,
  customerID: null,
  emailVerified: 'false', // kept as string because Cognito returns it as string
  vettedStatus: null,
};

function saveVettedStatus(vettedStatus) {
  if (!vettedStatus) {
    localStorage.removeItem('vettedStatus');
    return null;
  }
  localStorage.setItem('vettedStatus', JSON.stringify(vettedStatus));
  return vettedStatus;
}

function parseVettedStatusJsonString(jsonStr) {
  let vettedStatus;
  try {
    vettedStatus = JSON.parse(jsonStr);
  } catch {
    return null;
  }
  if (!vettedStatus ||
      typeof vettedStatus !== 'object' ||
      !vettedStatus.msg ||
      !vettedStatus.status ||
      typeof vettedStatus.msg !== 'string' ||
      typeof vettedStatus.status !== 'string') return null;
  return Object.fromEntries(Object.entries(vettedStatus).filter(([k]) => ['status', 'msg'].includes(k)));
}

export function loadVettedStatus() {
  return saveVettedStatus(parseVettedStatusJsonString(localStorage.getItem('vettedStatus')));
}

// eslint-disable-next-line default-param-last
function AuthReducer(state = authInitialState, action) {
  switch (action.type) {
    case types.SET_EMAIL:
      return { ...state, email: action.payload };
    case types.SET_USER:
      return { ...state, user: action.payload };
    case types.SET_SESSION:
      return { ...state, session: action.payload };
    case types.SET_CUSTOMER_ID:
      return { ...state, customerID: action.payload };
    case types.SET_VETTED_STATUS:
      saveVettedStatus(action.payload);
      return { ...state, vettedStatus: action.payload };
    case types.SET_EMAIL_VERIFIED:
      return { ...state, emailVerified: action.payload };
    case types.CLEAR_SESSION:
      saveVettedStatus(null);
      return authInitialState;
    default:
      return state;
  }
}

export default AuthReducer;
