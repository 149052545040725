import { useEffect, useCallback } from 'react';
import { t, getLanguage } from 'react-switch-lang';
import { useRouter } from 'next/router';
import { useDispatch } from 'react-redux';
import { moveFocusTo, setScreenReaderAlert } from './Accessibility';
import { events, logAmpEvent } from './Amplitude';
import { syncCart, unlockCart } from '../redux/actions/CartActions';
import { MAX_DENOM_QUANTITY } from './Constants';

export function formatCurrency(amount, omitDecimal) {
  const lang = getLanguage();
  const formatter = new Intl.NumberFormat(`${lang}-US`, {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: omitDecimal ? 0 : 2,
    maximumFractionDigits: omitDecimal ? 0 : 2,
  });

  return formatter.format(amount);
}

export function scrollToTop(focusID = 'main-cont') {
  window.scrollTo(0, 0);
  moveFocusTo(focusID);
}

export function isKYCRequired(kycType) {
  return kycType === 'A' || kycType === 'B';
}

export function isPaysafe(prodCode) {
  return prodCode === 'PAYSAU';
}

export function isPinGUI(storeGUI) {
  return storeGUI === 'EP' || storeGUI === 'VP';
}

export function invalidFormAlert(invalid, errors, singleError = false) {
  let checkCount = 0;
  let alertString = '';
  if (invalid) {
    // focus on first invalid element
    const focusElem = document.getElementsByName(Object.keys(errors)[0])[0];
    focusElem.focus();
    focusElem.scrollIntoView({ behavior: 'smooth', block: 'center' });

    // loop though all validation errors
    Object.keys(errors).forEach((item) => {
      if (!singleError) {
        if (errors[item] !== true) {
          const e = document.getElementById(`${item}Lbl`).firstChild.textContent;
          alertString += `${e}, `;
        } else {
          checkCount += 1;
        }
      }
      const itemFormatted = item.replace(/([A-Z])/g, ' $1');
      logAmpEvent(events.USER_ENTERED_INVALID_VALUE, {
        Field: itemFormatted.charAt(0).toUpperCase() + itemFormatted.slice(1),
        Reason: errors[item] !== true ? errors[item] : 'Required',
      });
    });
    if (singleError) {
      setScreenReaderAlert(Object.values(errors)[0]);
    } else {
      setScreenReaderAlert(checkCount === 0 ?
        t('ScreenReaderAlert_InvalidFormField').replace('xField', alertString) :
        `${t('ScreenReaderAlert_InvalidFormField').replace('xField', alertString)} ${t('ScreenReaderAlert_CheckAllCheckboxes').replace('xCount', checkCount)}`);
    }
  }
}

export function isProductInCart(prodCode, cartInfo) {
  return cartInfo?.items?.[prodCode];
}

export function giftCartAlert() {
  // eslint-disable-next-line no-alert
  window.alert(t('Cart_GiftAlert_Lbl').replace('xMax', MAX_DENOM_QUANTITY));
  return true;
}

export function promptGiftCartAlerts(prodCode, cartInfo, product) {
  if (!isProductInCart(prodCode, cartInfo)) return false;
  return cartInfo.items[prodCode][product.denom] + product.quantity > 10 ?
    giftCartAlert() : false;
}

export function paysafeCartAlert() {
  // eslint-disable-next-line no-alert
  window.alert(t('Cart_PaysafeAlert_Lbl'));
  return true;
}

export function paysafeCartConfirmation(denom) {
  // eslint-disable-next-line no-alert
  return window.confirm(t('Cart_PaysafeConfirmation_Lbl').replace('xDenom', denom.toString()));
}

export function promptPaysafeCartAlerts(prodCode, cartInfo, product) {
  if (prodCode !== 'PAYSAU' || !isProductInCart(prodCode, cartInfo)) return false;
  return cartInfo.items.PAYSAU[product.denom] ?
    paysafeCartAlert() : !paysafeCartConfirmation(product.denom);
}

/**
 * Use this hook with caution so that it won't show multiple dialog boxes on exit.
 * Ideally this should only be used for Page components.
 * @param {string} msgKey key for the window.confirm text
 * @param {string} logoutMsgKey key for the window.confirm text when logging out
 * @param {boolean} [confirmLogoutOnly] confirm only when logging out; defaults to false
 * @returns {Function} function to unsubcribe and disable form exit confirm
 */
export function useFormExitConfirm(msgKey, logoutMsgKey, confirmLogoutOnly = false) {
  const router = useRouter();
  const dispatch = useDispatch();
  const confirmationMsg = t(msgKey).replaceAll('\\n', '\n');

  const handleRouteChange = (useCallback((url) => {
    const isLogout = url.includes('logout');
    const isVerifyEmail = url.includes('verify-email');
    const msg = isLogout ? t(logoutMsgKey).replaceAll('\\n', '\n') : confirmationMsg;

    // eslint-disable-next-line no-alert
    if (!isVerifyEmail && (!confirmLogoutOnly || isLogout) && !window.confirm(msg)) {
      // have to throw literal to prevent nextjs from complaining about uncaught runtime exception
      throw 'User aborted redirect.'; // eslint-disable-line no-throw-literal
    } else {
      dispatch(unlockCart());
    }
  }, [confirmLogoutOnly, confirmationMsg, logoutMsgKey, dispatch]));

  const handleUnload = (useCallback((e) => {
    e.preventDefault();
    e.returnValue = confirmationMsg;
  }, [confirmationMsg]));

  const disable = (useCallback(() => {
    router.events.off('routeChangeStart', handleRouteChange);
    window.removeEventListener('beforeunload', handleUnload);
  }, [router, handleRouteChange, handleUnload]));

  useEffect(() => {
    if (confirmLogoutOnly) {
      router.events.on('routeChangeStart', handleRouteChange);
      return () => router.events.off('routeChangeStart', handleRouteChange);
    }

    router.events.on('routeChangeStart', handleRouteChange);
    window.addEventListener('beforeunload', handleUnload);
    return disable;
  }, [disable, confirmLogoutOnly]); // eslint-disable-line react-hooks/exhaustive-deps

  if (typeof window !== 'undefined') window.disableFormExitConfirm = disable;
  return disable;
}

/**
 * @param {object} hashToElementMap
 *   key-value pairs that map the url hash to the id of the element to scroll to;
 *   both the key and the value strings should start with `#`
 * @param {'smooth' | 'auto'} behavior
 *   behavior option to be passed into `window.scrollTo`; defaults to `smooth`
 */
export function useScrollToHash(hashToElementMap, behavior = 'smooth') {
  const router = useRouter();
  const cb = useCallback(() => {
    const { hash } = window.location;
    if (!hash || !hashToElementMap[hash]) return;
    const element = document.querySelector(hashToElementMap[hash]);
    if (!element) return;
    window.scrollTo({ top: element.offsetTop, behavior });
  }, [hashToElementMap, behavior]);
  useEffect(() => {
    if (!hashToElementMap) return undefined;
    router.events.on('routeChangeComplete', cb);
    router.events.on('hashChangeComplete', cb);
    return () => {
      router.events.off('routeChangeComplete', cb);
      router.events.on('hashChangeComplete', cb);
    };
  }, [router.events, hashToElementMap, cb]);
  useEffect(() => {
    if (hashToElementMap) cb();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return cb;
}

function lockedOnDifferentTab() {
  logAmpEvent(events.USER_VIEWED_CART_LOCKED_ALERT);
  // eslint-disable-next-line no-alert
  window.alert(t('Alert_CartLockedOnDifferentTab').replaceAll('\\n', '\n'));
  window.removeEventListener('focus', lockedOnDifferentTab);
}

export function useLocalStorageSync(store) {
  useEffect(() => {
    function handleCartChange(e) {
      if (e.key !== 'cart') return;
      store.dispatch(syncCart(e.newValue));
      try {
        const newCart = JSON.parse(e.newValue);
        if (newCart.locked) {
          window.addEventListener('focus', lockedOnDifferentTab);
        } else if (newCart.locked === false) { // need to check for false for unlocking
          window.removeEventListener('focus', lockedOnDifferentTab);
        }
      } catch {
        // continue regardless of error
      }
    }

    window.addEventListener('storage', handleCartChange);
    return () => window.removeEventListener('storage', handleCartChange);
  }, [store]);
}

export function formatProductDenom(denom) {
  return formatCurrency(denom, denom.toString().indexOf('.') < 0);
}

/**
 * If this function returns empty string, return from onSubmit (but keep loading state to true)
 * and let the ReCAPTCHA component's onChange callback trigger a resubmit.
 * When called during resubmit, a valid token will be returned by this function
 * so continue form submission with that token
 *
 * @param {*} reCaptchaRefValue the `.current` value of the ref passed into the ReCAPTCHA component
 * @param {() => *} onAbort callback to execute when user aborts reCAPTCHA challenge
 * @returns the reCAPTCHA token or empty string
 */
export function getReCaptchaToken(reCaptchaRefValue, onAbort) {
  // find the reCAPTCHA challenge window
  const list = document.querySelectorAll("iframe[src*='google.com/recaptcha/api2/bframe']");
  if (list.length > 0) {
    const recaptchaWindow = list[list.length - 1].parentNode.parentNode;

    new MutationObserver(([{ target }], observer) => {
      if (target.style.opacity === '0') { // challenge window closed
        logAmpEvent(events.GOOGLE_RECAPTCHA_CANCEL);
        onAbort();
        observer.disconnect();
      }
    }).observe(recaptchaWindow, { attributeFilter: ['style'] });
  }

  const reCaptchaToken = reCaptchaRefValue.getValue();

  if (!reCaptchaToken) {
    reCaptchaRefValue.executeAsync();
  } else {
    // reCaptchaToken will be sent to the API and consumed, so a new token is
    // required for the next submit. Reset in advance to make sure the
    // abort handler is attached to the new window.
    // Mutation observer attached to the previous window will also be disposed here
    reCaptchaRefValue.reset();
  }

  return reCaptchaToken;
}
